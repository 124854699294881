.hero-image-swiper {
  width: 200%;
  height: 100%;
  z-index: 0;
  margin-left: -50%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-image-swiper .swiper-slide.swiper-slide-active {
  box-shadow: 0px 0px 30px 15px #000;
  z-index: 1;
}

.hero-image-swiper .swiper-slide:not(.swiper-slide-active) {
  opacity: 0.5;
}